import { useState } from "react"
import Image from "../public/images/girl.jpg";

const RefundStatus = () => {


  return (<>
    <div className="banner-title">Refund Status</div>
    <div className="price-div row mb-4 pb-4">
      <div className="col-md-7">
      </div>
    </div>
  </>)
}

export default RefundStatus;