import "./page.css";

import Services from "../Segments/Services/Services";

const ForeginAccounts = () => {

  return (
    <>
      <div className="banner-title">FBAR And FACTA</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            The Foreign Bank Account Report (FBAR) and the Foreign Account Tax Compliance Act (FATCA) are two critical
            regulations that require U.S. taxpayers to report their foreign financial assets and accounts. Compliance with
            these regulations is essential to avoid significant penalties.  an overview of FBAR and FATCA.
          </p>
          <h3>FBAR</h3>
          <p>
            FBAR stands for Foreign Bank Account Report, officially known as FINCEN. It is required by the Bank Secrecy Act
            and mandates U.S. persons to report their foreign financial accounts if the aggregate value exceeds $10,000 at
            any time during the calendar year.
          </p>
          <h3>FATCA</h3>
          <p>
            FATCA stands for the Foreign Account Tax Compliance Act. It requires U.S. taxpayers to report specified foreign
            financial assets if the total value exceeds certain thresholds. FATCA also mandates foreign financial institutions
            to report information about financial accounts held by U.S. taxpayers.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default ForeginAccounts;