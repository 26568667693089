import "./page.css";

import Profile from "../public/images/male-icon.png";

const Career = () => {

  return (
    <>
      <div className="banner-title">Careers
      </div>
      <div className="careers-div carrer-main-div row">
        <h2 className="career-text">Careers</h2>
        <div className="col-md-8">
          <p>Besttaxfiler recognises its employees as one of the greatest assets. Without employees participation in
            core activities we can achieve nothing. Besttaxfiler offers its employees freedom at work and an
            opportunity to excel. It provides them motivating activities which guides them into professional
            excellence. Employees are encouraged to participate in the meeting to render their suggestions and
            opinions for fulfilment of goals.
          </p>
          <p>
            The training offered by Besttaxfiler is tailor made with competitive compensation and rewards. Besttaxfiler
            believes in more risks, more rewards. The commitment done by the company are never broken. A mutual
            relationship in between employee and employer made Besttaxfiler to blossom competitively in the field of taxation.
          </p>
          <p>If you have zeal to excel and explore your tomorrow future, we at Besttaxfiler provide you the right path.</p>
          <h3>Job Openings</h3>
          <hr></hr>
          <hr></hr>
          <div>
            <h4>Customer Support Representative</h4>
            <p>Required qualification: <span className="graduate">Any graduate</span></p>
            <p>Responsibilities and roles:</p>
            <ul className="career-ul">
              <li>Pleasing interpersonal skills</li>
              <li>Fluency in English</li>
              <li>Typing speed of 30 wpm</li>
              <li>Proficient in MS office</li>
              <li>Independent and able to adjust in fast pace movement</li>
              <li>Be able to resolve general queries</li>
            </ul>
          </div>
          <hr></hr>
          <hr></hr>
          <div>
            <h4>Tax Analyst</h4>
            <p>Required qualification: <span className="graduate">B.com, MBA</span></p>
            <p>Responsibilities and roles:</p>
            <ul className="career-ul">
              <li>Good communication skills</li>
              <li>Able to grasp the subject matter</li>
              <li>Good track record in academics</li>
              <li>knowledge on ms-word, excel etc</li>
              <li>General knowledge in taxation</li>
              <li>Willing to work in shifts</li>
            </ul>
          </div>
          <hr></hr>
          <hr></hr>
          <div>
            <h4>Senior Tax analyst</h4>
            <p>Required qualification: <span className="graduate">B.com, M.com, MBA, CA, EA</span></p>
            <p>Responsibilities and roles:</p>
            <ul className="career-ul">
              <li>Minimum 2-4 years of experience in taxation, accounting, finance</li>
              <li>Excellent communication skills</li>
              <li>Should have strong analytical knowledge</li>
              <li>Must have gained good track records</li>
              <li>Must be able to lead a team</li>
              <li>Must assist to Tax reviewer</li>
              <li>Willing to work in shifts</li>
            </ul>
          </div>
          <hr></hr>
          <hr></hr>
          <div>
            <h4>Tax Reviewer</h4>
            <p>Required qualification: <span className="graduate">B.com, M.com, EA, MBA</span></p>
            <p>Responsibilities and roles:</p>
            <ul className="career-ul">
              <li>Minimum 2-5 years of experience in taxation, accounting, finance</li>
              <li>Excellent communication skills</li>
              <li>Regulatory knowledge in taxation</li>
              <li>Must be attentive to details</li>
              <li>Should work with team</li>
              <li>Should be able to research</li>
              <li>Addressing client’s grievances</li>
              <li>Strong computer knowledge in typing</li>
              <li>Willing to work in shifts</li>
            </ul>
          </div>

        </div>
        <div className="col">
          <div className="form-div">
            <h3>Upload Resume to apply for the Job</h3>
            <input type="text" placeholder="Email" className="inputs" />
            <input type="text" placeholder="Name" className="inputs" />
            <input type="file" className="inputs" />
            <button>Upload</button>
          </div>
          <div className="loop-div">
            <p className="message">“Thank you so much for all your wonderful assistance & expertise. You were so helpful and efficient, and helped me through a nerve racking process.”</p>
            <div className="row quite-div">
              <i className="col-md-1"><img className="testimony-img" src={Profile} /></i>
              <div className="col" style={{ marginLeft: '70px', marginTop: "0" }}>
                <h3 className="quote">Ravi Jaiswal</h3>
                <p style={{ color: "#252a37", marginTop: "30px", fontSize: "14px", fontWeight: "bold" }}>California</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Career;