import { useNavigate } from 'react-router-dom';

import "./page.css";

import Profile from "../public/images/male-icon.png"

const CorporateServices = () => {

  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <>
      <div className="banner-title">Corporate Services</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <h2>Corporate Services</h2>
          <p>
            At Space Tax, we take a holistic approach to establishing business entities for our clients. Our tailored services
            are designed to align with your unique goals, whether that involves generating long-term cash flow from passive
            investments, pursuing an active business venture, or structuring for capital raising, a potential U.S. stock exchange
            listing, or a future sale. We conduct thorough analyses to determine the best U.S. entity type for your needs,
            considering both tax and legal perspectives.
          </p>
        </div>
        <div className="col">
          <div className="mb-4 pb-4">
            <div className="tags-sub-div" onClick={() => handleClick('/main-types')}>Main U.S. Entity Types</div>
            <div className="tags-sub-div" onClick={() => handleClick('/corporate-taxation')}>Corporate Taxation</div>
            <div className="tags-sub-div" onClick={() => handleClick('/accounting-and-bookkeeping')}>Accounting And Bookkeeping</div>
          </div>
          <div className="loop-div">
            <p className="message">“Thank you so much for all your wonderful assistance & expertise. You were so helpful and efficient, and helped me through a nerve racking process.”</p>
            <div className="row quite-div">
              <i className="col-md-1"><img className="testimony-img" src={Profile} /></i>
              <div className="col" style={{ marginLeft: '70px', marginTop: "0" }}>
                <h3 className="quote">Ravi Jaiswal</h3>
                <p style={{ color: "#252a37", marginTop: "30px", fontSize: "14px", fontWeight: "bold" }}>California</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CorporateServices;