import "./page.css";

import Services from "../Segments/Services/Services";

import LLCImage from "../public/images/image-800x500-3-768x480.jpg";


const TaxService = () => {

  return (
    <>
      <div className="banner-title">Advance Tax Planning</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Advance tax planning is a proactive approach to managing your tax liabilities and maximising your financial outcomes.
            By planning, individuals and businesses can make informed decisions that minimise taxes, optimise financial
            strategies, and avoid last-minute surprises. SpaceFiling provides key strategies and considerations for effective
            tax planning.
          </p>
          <h3>Key Strategies for Advance Tax Planning</h3>
          <p>Consulting Space tax professionals can provide personalised advice and strategies tailored to your specific situation.</p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default TaxService;