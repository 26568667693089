import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import Profile from "../../public/images/male-icon.png";

const Services = () => {

  const navigate = useNavigate();
  const [data] = useState([
    { name: "Advance Tax Planning", route: "/advance-tax-planning" },
    { name: "Tax Preparation", route: "/tax-preparation" },
    { name: "Audit Assistance", route:"/audit-assistance"},
    { name: "Extension Filing", route: "/extension-filing" },
    { name: "ITIN Processing", route: "/itin-processing" },
    { name: "FBAR and FATCA", route: "/fbar-and-facta" },
    { name: "Business Tax Filing", route: "/business-tax-filing" },
    { name: "Indian Taxes", route: "/indian-taxes" }
  ])

  const handleClick = (route) => {
    navigate(route);
  };

  return <>
    <div className="mb-4 pb-4">
      {
        data.map(d => {
          return <div onClick={() => handleClick(d.route)} className="tags-sub-div">{d.name}</div>
        })
      }
    </div>
    <div className="loop-div">
      <p className="message">“Thank you so much for all your wonderful assistance & expertise. You were so helpful and efficient, and helped me through a nerve racking process.”</p>
      <div className="row quite-div">
        <i className="col-md-1"><img className="testimony-img" src={Profile} /></i>
        <div className="col" style={{ marginLeft: '70px', marginTop: "0" }}>
          <h3 className="quote">Ravi Jaiswal</h3>
          <p style={{ color: "#252a37", marginTop: "30px", fontSize: "14px", fontWeight: "bold" }}>California</p>
        </div>
      </div>
    </div>
  </>

}

export default Services;