import "./page.css";

import Services from "../Segments/Services/Services";

const IndividualService = () => {

  return (
    <>
      <div className="banner-title">IndividualService</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Space Tax is a specialized tax filing business that complies with IRS Circular 230 and is registered as an
            Electronic Return Originator (ERO).
          </p>
          <ul className="career-ul">
            <li>Federal</li>
            <li>State</li>
            <li>Local/County</li>
          </ul>
          <h3>Federal, State and Local Taxes
          </h3>
          <p>Federal taxes in the United States are crucial for individuals and businesses alike. The federal tax system funds
            essential government services, including national defence, social security, and public infrastructure. Helping you
            navigate this complex landscape.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default IndividualService;