import { Routes, Route, Navigate } from 'react-router-dom';

import './App.css';

import Header from './Segments/Header/Header';
import Footer from './Segments/Footer/Footer';

import CCR from './pages/CCR';
import LLC from './pages/LLC';
import Home from './pages/Home';
import ITIN from "./pages/ITIN";
import Terms from "./pages/Terms";
import Login from './pages/Login';
import Career from './pages/Career';
import Contact from './pages/Contact';
import Benifits from './pages/Benifits';
import Individual from './pages/Individual';
import TaxService from './pages/TaxService';
import Testimonial from './pages/Testimonial';
import OtherService from './pages/OtherService';
import RefundStatus from './pages/RefundStatus';
import PayrollService from './pages/PayrollService';
import AccountService from './pages/AccountService';
import ForeginAccounts from './pages/ForeginAccounts';
import TaxRepresentation from './pages/TaxRepresentation';
import ServicesAmendment from './pages/ServicesAmendment';
import FinansialPlanning from './pages/FinansialPlanning';
import CorporateServices from "./pages/CorporateServices"
import IndividualService from './pages/IndividualService';
import IndianTaxes from './pages/IndianTaxes';

function App() {
  return (
    <div className="tax-app">
      <Header />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/sign-up" element={<Login />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/audit-assistance" element={<ITIN />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/advance-tax-planning" element={<TaxService />} />
        <Route path="/about-us" element={<Testimonial />} />
        <Route path="/benefit-with-btf" element={<Benifits />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/business-tax-filing" element={<OtherService />} />
        <Route path="/refund-Status" element={<RefundStatus />} />
        <Route path="/tax-preparation" element={<PayrollService />} />
        <Route path="/main-types" element={<LLC />} />
        <Route path="/corporate-taxation" element={<CCR />} />
        <Route path="/accounting-and-bookkeeping" element={<AccountService />} />
        <Route path="/extension-filing" element={<TaxRepresentation />} />
        <Route path="/individual-tax-preparation" element={<Individual />} />
        <Route path="/itin-processing" element={<ServicesAmendment />} />
        <Route path="/financial-planning-services" element={<FinansialPlanning />} />
        <Route path="/fbar-and-facta" element={<ForeginAccounts />} />
        <Route path="/corporate-services" element={<CorporateServices />} />
        <Route path="/individual-service" element={<IndividualService />} />
        <Route path="/indian-taxes" element={<IndianTaxes />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
