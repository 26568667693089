import "./footer.css";
import Logo from "../../public/images/spacing-logo.png";

import { useNavigate } from 'react-router-dom'
import { useState } from "react";

const Footer = () => {

  const [accData] = useState([
    { header: "Home", url: "/home" },
    { header: "About Us", url: "/about-us" },
    { header: "Refund Status", child: [], url: "/refund-Status" },
    { header: "Contact", child: [], url: "/contact" },
    { header: "Sign Up", child: [], url: "/sign-up" },
  ])

  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <div className="footer_main_div row">
      <div className="col-md-5">
        <div><img width="100%" src={Logo} /></div>
        <p>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
          literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
          College in Virginia, looked up one of the more obscure Latin words, consectetur
        </p>
      </div>
      <div className="col offset-md-1">
        <h2>QUICK LINKS</h2>
        {
          accData.map(ql => {
            return <p onClick={() => handleClick(ql.url)}>{ql.header}</p>
          })
        }
      </div>
      <div className="col offset-md-1">
        <h2>CONTACT</h2>
        <p>US: +1 999 999 999</p>
        <p>IND: +91 999 999 999</p>
        <p>support@spacetaxfiling.com</p>
        <p>2650 Route 130, Suite, E,</p>
        <p>Cranbury, N3 08512</p>
        <i className="fa fa-facebook"/>
        <i className="fa fa-linkedin" />
        <i className="fa fa-pinterest" />
        <i className="fa fa-instagram" />
      </div>
    </div>
  )
}

export default Footer;
