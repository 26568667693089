import "./page.css";
import ContactUs from "../public/images/Contact-Us4.jpg";

const Contact = () => {

  return (
    <div>
      <div className="banner-title">Contact US</div>

      <div className="contact-us-div row">
        <div className="col mt-4">
          <div className="row contact-divs">
            <div className="col">
              <i class="fa fa-address-card-o"></i>
              <h3>Location</h3>
              <p>159 Meadow St, Naugatuck,Connecticut-06770</p>
            </div>
            <div className="col offset-md-1">
              <i class="fa fa-address-book" />
              <h3>Contact</h3>
              <p>+1 860-516-6595</p>
            </div>
            <div className="col  offset-md-1">
              <i class="fa fa-envelope" />
              <h3>Email</h3>
              <p>support@taxfilers.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;