import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';

import Logo from "../../public/images/spacing-logo.png";
import "./header.css";


const Header = () => {

  const [isShow, updateIsShow] = useState(false);
  const [accData] = useState([
    { header: "Home", url: "/home", child: [] },
    { header: "About Us", url: "/about-us" },
    {
      header: "Our Services",
      child: [
        {
          header: "Individual Services",
          child: [
            { header: "Advance Tax Planning", url: "/advance-tax-planning" },
            { header: "Tax Preparation", url: "/tax-preparation" },
            { header: "Audit Assistance", url: "/audit-assistance" },
            { header: "Extension Filing", url: "/extension-filing" },
            { header: "ITIN Processing", url: "/itin-processing" },
            { header: "FBAR AndFACTA", url: "/fbar-and-facta" },
            { header: "Business Tax Filing", url: "/business-tax-filing" },
            { header: "Indian Taxes", url: "/indian-taxes" }
          ],
          url: "/individual-service"
        },
        {
          header: "Corporate Services",
          child: [
            { header: "Main U.S. Entity Types", url: "/main-types" },
            { header: "Corporate Taxation", url: "/corporate-taxation" },
            { header: "Accounting And Bookkeeping", url: "/accounting-and-bookkeeping" },
          ],
          url: "/corporate-services"
        }]
    },
    { header: "Refund Status", child: [], url: "/refund-Status" },
    { header: "Contact", child: [], url: "/contact" },
    { header: "Sign Up", child: [], url: "/sign-up" },
  ])

  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (route) => {
    if (route) {
      navigate(route);
      updateIsShow(false)
    }
  };



  return (
    <div className="main-row">
      <div id="top-bar" className="top-bar highlight">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <ul className="top-info">
                <li>
                  <span className="icon-info">
                    <i className="fa fa-phone"></i>
                  </span>
                  <div className="info-wrapper">
                    <p>+91 957-313-13125</p>
                  </div>
                </li>
                <li>
                  <span className="icon-info">
                    <i className="fa fa-envelope" />
                  </span>
                  <div className="info-wrapper">
                    <p>support@spacetaxfiling.com</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 text-right">
              <div className="top-social row">
                {/* <div className='col-md-6'>
                  <a title="Refer your Friend"><div className="blink">Refer your friends</div></a>
                </div> */}
                <div className='col'>
                  <a title="Facebook" target="_blank">
                    <span className="social-icon"><i className="fa fa-facebook"></i></span>
                  </a>
                  <a title="linkedin" target="_blank">
                    <span className="social-icon"><i className="fa fa-linkedin"></i></span>
                  </a>
                  <a title="instagram" target="_blank">
                    <span className="social-icon"><i className="fa fa-instagram"></i>
                    </span>
                  </a>
                  <a title="Twitter" target="_blank">
                    <span className="social-icon"><i className="fa fa-twitter"></i></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="nav_menu">
        <div className="container-fluid">
          <div className="row">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <a className="navbar-brand" onClick={() => handleClick('/home')}><img src={Logo} alt="Logo" /></a>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                  <ul className="navbar-nav">
                    <li className={`nav-item`} onClick={() => handleClick('/home')}>
                      <a className="nav-link">Home</a>
                    </li>
                    <li className={`nav-item`} onClick={() => handleClick('/about-us')}>
                      <a className="nav-link">About US</a>
                    </li>
                    <li className={`nav-item dropdown`}>
                      <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Our Services
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown-menu-item" aria-labelledby="navbarDropdown">
                          <li className="sub-nav-item dropdown">
                            <a className="dropdown-item" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false" onClick={() => handleClick('/individual-service')}>
                              Individual Services
                            </a>
                            <div className="sub-dropdown-menu" aria-labelledby="navbarDropdown">
                              <a className="dropdown-item" onClick={() => handleClick("/advance-tax-planning")}>Advance Tax Planning</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick("/tax-preparation")}>Tax Preparation</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick("/audit-assistance")}>Audit Assistance</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick("/extension-filing")}>Extension Filing</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick("/itin-processing")}>ITIN Processing</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick("/fbar-and-facta")}>FBAR And FACTA</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick("/business-tax-filing")}>Business Tax Filing</a>
                              <a className="dropdown-item" onClick={() => handleClick("/indian-taxes")}>Indian Taxes</a>

                            </div>
                          </li>
                        </li>
                        <hr></hr>
                        <li className="dropdown-menu-item" aria-labelledby="navbarDropdown2">
                          <li className="dropdown">
                            <a className="dropdown-item" id="navbarDropdown" role="button" onClick={() => handleClick('/corporate-services')}>
                              Corporate Services
                            </a>
                            <div className="sub-dropdown-menu" aria-labelledby="navbarDropdown">
                              <a className="dropdown-item" onClick={() => handleClick('/main-types')}>Main U.S. Entity Types</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick('/corporate-taxation')}>Corporate Taxation</a><hr></hr>
                              <a className="dropdown-item" onClick={() => handleClick('/accounting-and-bookkeeping')}>Accounting And Bookkeeping</a>
                            </div>
                          </li>
                        </li>
                      </ul>
                    </li>
                    <li className={`nav-item`} onClick={() => handleClick('/refund-Status')}>
                      <a className="nav-link">Refund Status</a>
                    </li>
                    <li className={`nav-item`} onClick={() => handleClick('/contact')}>
                      <a className="nav-link">Contact</a>
                    </li>
                    <li className={`nav-item ${location.pathname === '/sign-up' ? ' active' : ''}}`} onClick={() => handleClick('/sign-up')}>
                      <a className="nav-link" style={{ color: "#932b2f" }}>Sign Up</a>
                    </li>
                  </ul>
                </div>
                <div className='justify-div' onClick={() => updateIsShow(!isShow)}>
                  <i className="fa fa-align-justify"></i>
                </div>
                {
                  isShow &&
                  <div className="div-acc">
                    <Accordion alwaysOpen>
                      {
                        accData.map((acc, i) => {
                          return (
                            <Accordion.Item eventKey={i}>
                              <Accordion.Header
                                className={acc?.child?.length ? '' : 'hide-arrow'}
                                onClick={() => handleClick(acc.url)}
                              >{acc.header}
                              </Accordion.Header>
                              <Accordion.Body>
                                {
                                  acc.child?.map((ch, j) => {
                                    return (
                                      <Accordion.Item eventKey={i + "" + j}>
                                        <Accordion.Header
                                          className={ch.child.length ? '' : 'hide-arrow'}
                                          onClick={() => handleClick(ch.url)}
                                        >{ch.header}</Accordion.Header>
                                        {
                                          ch.child.map((chh, k) => {
                                            return <Accordion.Body onClick={() => handleClick(chh.url)}>{chh.header}</Accordion.Body>
                                          })
                                        }
                                      </Accordion.Item>
                                    )
                                  })
                                }
                              </Accordion.Body>
                            </Accordion.Item>
                          )
                        })
                      }
                    </Accordion>
                  </div>
                }
              </div>
            </nav>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Header;